<template>
  <div class="_sustainability">
    <section class="_section__hero-wrapper">
      <section class="_section__sustainability--intro">
        <div class="_media">
          <picture class="_picture--1">
            <source
              media="(min-width: 1200px)"
              srcset="@/assets/images/sustainability_grow_1-1200.jpg"
            />
            <source
              media="(min-width: 600px)"
              srcset="@/assets/images/sustainability_grow_1-Small.jpg"
            />
            <img
              class="_image"
              src="@/assets/images/sustainability_grow_1-Small.jpg"
              alt="sustainability"
            /> </picture
          ><picture class="_picture--2">
            <source
              media="(min-width: 1200px)"
              srcset="@/assets/images/sustainability_grow_2-1200.jpg"
            />
            <source
              media="(min-width: 600px)"
              srcset="@/assets/images/sustainability_grow_2-Small.jpg"
            />
            <img
              class="_image"
              src="@/assets/images/sustainability_grow_2-Small.jpg"
              alt="sustainability"
            />
          </picture>
        </div>
        <div class="_card--raised">
          <h2 class="_title">Introduction</h2>
          <div class="_paragraphs">
            <p class="_text">
              Over the past few years, we've realised that the lesser we
              interfere with nature, the more it flourishes. Ever since, our
              core value has been to promote ecological balance and conserve
              biodiversity. This ensured that we have a thriving farm with
              healthy produce.
            </p>
            <p class="_text">
              After a few years of research and development, we've managed to
              have an abundant organic farm produce which we can offer to our
              guests and visitors. The farm is completely based on traditional
              farming techniques. Once you visit our farm, you won't be able to
              resist yourself from biting into one of our fresh fruits.
            </p>
          </div>
        </div>
      </section>
    </section>

    <section class="_section__custom--overlap">
      <div class="_media">
        <picture class="_picture">
          <source
            media="(min-width: 1200px)"
            srcset="@/assets/images/sustainability_sufficient-1200.jpg"
          />
          <source
            media="(min-width: 600px)"
            srcset="@/assets/images/sustainability_sufficient-Small.jpg"
          />
          <img
            class="_image"
            src="@/assets/images/sustainability_sufficient-Small.jpg"
            alt="sustainability"
          />
        </picture>
      </div>
      <div class="_card--raised">
        <h2 class="_title">Self Sufficient</h2>
        <div class="_paragraphs">
          <p class="_text">
            By the time our food makes it to our plates, it has already lost
            most of its nutrients. Our food goes through extensive processing.
            Be it genetically modified seeds or excessive use of chemical
            fertilizers. This information got us invested in not just eating but
            harvesting wholesome food. Even if that meant documenting the food
            every step of the way.
          </p>
          <p class="_text">
            Today we've been able to produce fruits and vegetables that are not
            only grown organically but nurtured through and through. We take
            pride in serving a fresh plate of guilt free food that will
            completely nourish you.
          </p>
        </div>
      </div>
    </section>

    <section class="_section__custom--overlap">
      <div class="_media">
        <picture class="_picture">
          <source
            media="(min-width: 1200px)"
            srcset="@/assets/images/sustainability_plantation-1200.jpg"
          />
          <source
            media="(min-width: 600px)"
            srcset="@/assets/images/sustainability_plantation-Small.jpg"
          />
          <img
            loading="lazy"
            class="_image"
            src="@/assets/images/sustainability_plantation-Small.jpg"
            alt="sustainability"
          />
        </picture>
      </div>
      <div class="_card--raised">
        <h2 class="_title">Our Plantations</h2>
        <div class="_paragraphs">
          <p class="_text">
            We share a long journey of love and care with our trees. From a seed
            to a full blown tree, we've religiously marked the progress of our
            food forest. With 15 years of dedication, we are now reaping the
            fruits of our efforts. Our food forest is home to a variety of trees
            such as mango, chikoo, custard apple, guava, coconut, kokam, litchi,
            jamun and pomegranate trees.
          </p>
          <p class="_text">
            We adopted the Miyawaki method of afforestation to ensure ecological
            balance. For a balanced ecological system a lot of insects and birds
            that are often shunned away, play an essential role. Their presence
            is a bio indicator that the farm is thriving and could serve as a
            home to all the birds and insects. At Kamal Farms, we welcome every
            little help from worms and insects because we consider them our
            friends.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Sustainability",
  metaInfo: {
    title: "Sustainability",
    tags: ["Organic Farming", "Self Sufficient", "Organic Plantations"],
  },
};
</script>

<style scoped lang="scss">
// FunFact: If this style template is empty, this component will not render any other class(s)
._sustainability {
  ._section__sustainability--intro {
    @extend ._section__custom--overlap;
    ._title {
      padding: 0 0 $paddingMinimum;
    }
    ._media {
      display: grid;
      grid-template-rows: 1fr 6fr 5fr;
      grid-template-columns: 0.5fr 1.5fr 1fr 3fr;
      ._picture {
        &--1 {
          @extend ._picture;
          grid-area: 2/2/3/4;
          z-index: 1;
        }
        &--2 {
          @extend ._picture;
          grid-area: 1/3/-1/-1;
        }
      }
    }
  }
}
@media screen and (max-width: 400px) {
  ._sustainability {
    ._section__sustainability--intro {
      ._media {
        ._picture {
          &--1 {
            grid-area: 2/1/3/4;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 600px) {
  ._sustainability {
  }
}
@media screen and (min-width: 1200px) {
  ._sustainability {
  }
}
</style>